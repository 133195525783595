// Lessons06.js
import React from "react";
import Slider from "../../../../components/Slider/Slider";
import styles from "./Lesson.module.scss";

const Lesson06 = () => {
  return (
    <div>
      <div>
        <p>Если не нужна ткань с отпечатками листьев, или нужен неравномерный фон, вот один из способов, как можно
          этого добиться.</p>
        <p>Для начала, подготовьте все необходимые материалы:</p>
        <ol>
          <li>Ткань (атлас, хлопок, лен и т.д.)</li>
          <li>Растительные красители (золотарник, индиго, пижма, зверобой и т.д.)</li>
          <li>Алюмокалиевые квасцы 10% от веса ткани.</li>
          <li>Железное одеяло (1 гр.)</li>
          <li>Ломанные листья (непригодные для экопринта)</li>
          <li>Плёнку</li>
        </ol>
        <p><strong>Шаг 1: Подготовка ткани</strong><br/>
          Перед началом окрашивания, ткань нужно постирать.</p>
        <p><strong>Шаг 2: Окрашивание золотарником</strong><br/>
          Протравите ткань алюмокалиевыми квасцами (10%) и оставьте на 2 часа. Затем, окрасьте ткань золотарником,
          погрузив ткань в экстракт золотарника (предварительно приготовьте его). Разложите ткань на плёнку, смочите
          из пульверизатора ткань. Сожмите ткань гармошками-жамками.</p>
        <p><strong>Шаг 3: Окрашивание с использованием листьев и растений</strong><br/>
          Повторите процесс окрашивания золотарником, но на этот раз, положите на ткань ломанные листья (непригодные
          для экопринта) и посыпьте сухим золотарником, пижмой и зверобоем. Укрыть железным одеялом и оставить на
          несколько часов.</p>
        <p><strong>Шаг 4: Окрашивание индиго</strong><br/>
          Последний этап - окрашивание индиго. Заложите ткань складками и окрасьте ее в индиго.</p>
        <p><strong>Шаг 5: Фиксация цвета</strong><br/>
          После окончания окрашивания, ткань нужно промыть в холодной воде до полного удаления излишков красителя.
          Затем, просушите ткань.</p>
        <p>Теперь у вас есть уникальная ткань с неравномерным фоном и отпечатками листьев и растений.
          Экспериментируйте с разными растительными красителями и материалами, создавайте свои уникальные дизайны и
          делитесь своими результатами!</p>

        <div className={styles.sliderContainer}>
          <Slider autoPlay={true} autoPlayTime={5000} width="450px" height="100%" images={[
            {src: '/images/lessons/lesson6_01.jpeg', alt: 'Image 1'},
            {src: '/images/lessons/lesson6_02.jpeg', alt: 'Image 2'},
            {src: '/images/lessons/lesson6_03.jpeg', alt: 'Image 3'}
          ]}/>
        </div>
      </div>
    </div>
  );
};

export default Lesson06;