// galleries.js
import { aboutHerbs, byForestRoads, outOfTime, forestFaires } from './images';

const formatDescription = (description) => {
  return description.split('\n').filter(paragraph => paragraph.trim() !== '').map((paragraph, index) => (
    <p key={index}>{paragraph}</p>
  ));
};

const galleries = [
  {
    id: 1,
    title: 'Лесные феи',
    description: formatDescription('Они убаюкивали лес, шептали слова благословения, обещая вернуться с весной и разбудить всех от зимнего сна.\n' +
      'Они существуют:)\n' +
      'Вот так едешь в метро, идёшь по улице и не знаешь, что проходящая мимо девушка - волшебница или эльф.\n' +
      'Так долго я бродила по лесам и полям, собирая травы и листья для окрашивания тканей, что природа открыла мне свою прекрасную волшебную сторону, где живут очаровательные лесные жители. А хлопот у них разве, что укладывать жителей леса на зиму спать, да пробуждать весной, радоваться лету и дарить всем счастье.\n' +
      'Видимо, волшебство должно было случиться, и, благодаря видению фотографа Сабины и вжившимся в образы Анны и Александры, я готова вам поведать эту сказку.'),
    items: forestFaires.map((image, index) => ({
      href: `#image${index + 1}`,
      src: image.src,
      alt: `Image ${index + 1}`
    }))
  },
  {
    id: 2,
    title: 'Про травы. Протравы',
    description: formatDescription('Долгое время меня останавливало слово "протравы"...\n' +
      'Казалось, что это что-то ядовитое и не до конца мне понятное. Я думаю многие с подобным сталкивались. Оказалось, что это не совсем так.\n' +
      'Теперь я знаю, как пользоваться протравами, чтобы травы оставляли свой пигмент на ткани.'),
    items: aboutHerbs.map((image, index) => ({
      href: `#image${index + 1}`,
      src: image.src,
      alt: `Image ${index + 1}`
    }))
  },
  {
    id: 4,
    title: 'Лесными тропками',
    description: formatDescription('Это коллекция одежды и аксессуаров, окрашенных природными красителями, в технике экопринт.\n' +
      'До создания синтетических красителей мастера прошлого окрашивали ткани корнями, корой, травами, жучками.\n' +
      'Марена, индиго, кошениль, кампеш, золотарник, ольха и другие природные красители, вдохновляют на создание уникальных аксессуаров и одежды.\n' +
      'Удивительную палитру красок дарит природа, сохраняя традиции натурального окрашивания ткани. Можно увидеть, как человек воспринимал мир в природных красках.\n' +
      'Прикосновение...\n' +
      'Ткани мы доверяем самое главное, а именно - прикосновение к коже!\n' +
      'Все, что мы знаем о себе и об этом мире, нам рассказывают наши органы чувств. Тактильные ощущения, получаемые от прикасающейся к нам ткани, могут успокаивать и дарить чувство радости и уюта. Гармония цвета природы доступная каждый день.\n' +
      'Ткани, окрашенные природными красителями, сохраняют полезные свойства шерсти и шёлка. У натуральных красителей есть одно удивительное свойство - они меняют свой тон при разном освещении. Благородные оттенки легко интегрируются с разными образами.\n' +
      'Одежда и аксессуары, расписанные природными красителями, всегда вне времени, они будут радовать вас долгие годы.'),
    items: byForestRoads.map((image, index) => ({
      href: `#image${index + 1}`,
      src: image.src,
      alt: `Image ${index + 1}`
    }))
  },
  {
    id: 3,
    title: 'Вне времени',
    description: formatDescription('Одним из самых универсальных аксессуаров может быть шелковый платок или шарф. Он способен преобразить любой скучный наряд и стать особенным ярким аккордом в образе.\n' +
      '\n'),
    items: outOfTime.map((image, index) => ({
      href: `#image${index + 1}`,
      src: image.src,
      alt: `Image ${index + 1}`
    }))
  },

];

export default galleries;