// Lessons08.js
import React from "react";

const Lesson08 = () => {
  return (
    <div>
      <div>
        <p>Как ухаживать за изделиями, окрашенными естественными органическими красителями, способом экопринт?</p>
        <p>Очень просто!</p>
        <p>Стирать ручками в тёплой воде с мягкими моющими средствами, предназначенными для стирки шелка и шерсти. Для
          этого можно применять детское мыло. Предварительно растворив моющие средства в воде, замочите ткань на 5
          минут.</p>
        <p>Следы загрязнения удаляются мягкими движениями, перебирая ткань в воде, чтобы не нарушить структуру шелка
          или войлока. Полоскать следует в прохладной воде с несколькими каплями уксуса.</p>
        <p>Отжимайте ткань руками, сжимая её в комок. Также для отжима можно использовать и полотенце.</p>
        <p>Гладить утюгом, предварительно выставив соответствующий ткани режим. Оттиски листов придают ткани
          дополнительный декоративный эффект и поэтому не стоит усердствовать при глажке, чтобы не испортить и не
          нарушить фактуру экопринта.</p>
        <p>Описанный выше способ подойдёт и для ухода за войлоком или нуновойлоком.</p>
        <p>Стирать при низкой температуре, макс. 40°;</p>
        <p>Не использовать агрессивные, сухие и отбеливающие порошки;</p>
        <p>Для деликатных волокон отдавайте предпочтение нейтральному и жидкому мылу;</p>
        <p>Не сушите волокна под прямыми лучами солнца.</p>
      </div>
    </div>
  );
};

export default Lesson08;